@import "../../vars";

.motor-with-batteries {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .motor-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: 60%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 4;
        }
    }

    .batteries-container {
        position: relative;
        left: -20px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        height: 100%;
        align-items: flex-start;
        width: 40%;

        img {
            position: relative;
            height: calc(100% / 3);
            visibility: hidden;
            transition: top 0.5s ease;

            &:nth-child(1) {
                visibility: visible;
                z-index: 1;
            }

            &:nth-child(2) {
                z-index: 2;
                top: calc(100% / 3);
            }

            &:nth-child(3) {
                z-index: 3;
                top: calc(100% / 3 * 1.5);
            }
        }

        &.Large {
            img {
                &:nth-child(2) {
                    visibility: visible;
                    top: calc(100% / 3 / 2);
                }
            }
        }

        &.XLarge {
            img {
                &:nth-child(2) {
                    visibility: visible;
                    top: calc(100% / 3 / 2);
                }

                &:nth-child(3) {
                    visibility: visible;
                    top: calc(100% / 3);
                }
            }
        }

        &.white {
            img {
                &:nth-child(2) {
                    left: 3px;
                }

                &:nth-child(3) {
                    left: 6px;
                }
            }
        }
    }
}
