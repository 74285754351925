@import "../../vars";

.buttonlist.summary-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        width: 100%;
    }

    a {
        text-decoration: none;
    }

    @media only screen and (max-width: $desktop) {
        .nav-link,
        button {
            width: auto;
        }
    }

    .nav-link,
    button {
        box-sizing: border-box;
        height: 120px;
        display: flex;
        background: none;
        padding: 0px;
        overflow: hidden;
        font-family: "HCo Gotham", Sans-serif;
        border-radius: 5px;
        margin-bottom: 8px;
        border: 2px solid #e0e0e0;
        @media only screen and (max-width: $m-desktop) {
            height: 60px;
        }

        .image-box {
            width: 120px;
            height: 116px;
            @media only screen and (max-width: $m-desktop) {
                width: 60px;
                height: 56px;

                img {
                    width: 60px;
                    height: 56px;
                }
            }
        }

        .info-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            height: 100%;
            color: $evoy-black;

            .yellow-text-row {
                display: flex;
                text-align: right;

                @media only screen and (max-width: $m-desktop) {
                    display: none;
                }

                span {
                    font-size: 12px;
                    color: transparent;
                    text-transform: none;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-weight: 600;
                    width: 100%;

                    .MuiSvgIcon-root {
                        vertical-align: top;
                        font-size: 1.2rem;
                    }
                }
            }

            .name-row {
                display: flex;
                flex-direction: row;
                text-align: left;
                @media only screen and (max-width: $m-desktop) {
                    margin-top: 10px;
                }

                span {
                    text-transform: uppercase;
                    font-size: 22px;
                    font-weight: bold;
                    width: 100%;
                }

                @media only screen and (max-width: $m-desktop) {
                    span {
                        font-size: 15px;
                    }
                }
            }

            .row {
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                font-weight: normal;

                span {
                    font-size: 15px;
                    width: 100%;
                    text-align: right;
                }

                span#left {
                    text-align: left;
                    margin-top: 10px;
                }

                span#currency {
                    font-weight: 600;
                }

                span#price {
                    font-size: 22px;
                    font-weight: bold;
                    margin-right: 10px;
                }

                span#included {
                    margin-top: 10px;
                    margin-right: 10px;
                    font-weight: normal;
                }

                @media only screen and (max-width: $m-desktop) {
                    span {
                        font-size: 10px;
                    }
                    span#left,
                    span#included {
                        margin-top: 5px;
                    }

                    span#price {
                        font-size: 15px;
                    }
                }
            }
        }
        @media only screen and (max-width: $m-desktop) {
            .selected-system-text {
                display: flex;
                align-self: center;
                flex: 1;
                margin-left: 15px;

                span {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }

    .nav-link:focus {
        outline: none;
    }
    .nav-link:hover,
    .nav-link:focus {
        outline: none;
        border: 2px solid $evoy-yellow;
        .info-box {
            .yellow-text-row {
                .change-selections-text {
                    display: inline;
                    color: $evoy-black;
                    background-color: $evoy-yellow;
                    padding: 4px;
                    font-weight: bold;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $evoy-lightning;
                        }
                    }
                }
            }
        }
    }
}

.sum {
    margin-top: 20px;
    text-align: right;
    width: 100%;

    @media only screen and (max-width: $desktop) {
        .total-currency {
            margin-right: 10px;
        }
    }
    span {
        color: $evoy-black;
        font-size: 15px;
        font-family: "HCo Gotham", Sans-serif;
        font-weight: normal;
    }
    span#price {
        margin-left: 0px;
        font-size: 22px;
        font-weight: bold;
    }

    @media only screen and (max-width: $m-desktop) {
        margin-top: 10px;

        span {
            font-size: 10px;
        }

        span#price {
            font-size: 15px;
        }
    }
}
