@import "../../vars";

.select-discharge-type-container {
    h1 {
        font-size: 14px;
        color: $evoy-black;
        text-transform: uppercase;
        font-weight: 300;
    }

    button {
        box-sizing: border-box;
        display: block;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 500;
        font-size: 18px;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 8px;

        &:hover,
        &:focus {
            color: $evoy-yellow;
        }
    }
}
