@import "../../vars";
@import "../../mixins";

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.2) !important;
    z-index: 999;
}

.ReactModal__Body--open {
    #root {
        filter: blur(4px);
        height: 100%;
    }
}

.ReactModal__Content {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    overflow: auto;
    background-color: #f6f8f9;
    padding: 0 !important;
    margin: 0 auto;
}

.modal-container {
    display: flex;
    border-radius: 10px;
    min-height: 100%;
    position: relative;
    padding: 0 1rem 1rem;
    background: #f6f8f9;

    .column {
        flex-direction: column;
        &.menu {
            background: #ffffff;
        }
    }

    .row {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
    }

    h1 {
        color: $evoy-black;
    }

    .enova-link {
        display: block;
        color: $evoy-yellow;
        margin-bottom: 0;
        font-weight: bold;
    }

    .enova-icon {
        margin: 0.5rem 0;
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    .menu {
        display: none;
    }

    .content {
        width: 100%;
        padding: 0;
        min-height: 100%;
        background: #f6f8f9;

        h3 {
            @include x-medium-text;
            font-weight: 400;
        }

        .slider-space {
            margin-bottom: 60px;
        }

        .MuiSlider-track {
            border-radius: 0px;
        }

        .MuiSlider-rail {
            background: white;
            border-radius: 0px;
            box-shadow: 0px 2px 5px #0000002c;
        }

        .MuiSlider-markLabel[data-index="0"] {
            transform: translateX(0);
            font-size: 14px;
            color: $evoy-black;
            position: absolute;
        }

        .MuiSlider-markLabel[data-index="1"] {
            left: 97% !important;
            font-size: 14px;
            color: $evoy-black;
            position: absolute;
        }

        .cost-space {
            margin: 0;
            margin-top: 43px;
        }

        // buttons
        .nxt-btn {
            box-sizing: border-box;
            margin-bottom: 8px;
            display: inline-block;
            min-width: 189px;
            height: 46px;
            background: $evoy-yellow;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: $evoy-black;
            border: none;
            border-radius: 5px;
            line-height: 47px;
            margin-left: 10px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 0 0.5rem;

            &:disabled,
            &[disabled] {
                border: 1px solid #999999;
                background-color: #cccccc;
                color: #666666;
            }

            img {
                margin-top: 5px;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $evoy-lightning;
                }
            }
        }

        .navigation {
            margin-top: 1rem;
            float: right;
        }

        .input-container {
            width: 50%;
            margin-right: 25px;

            input.email {
                width: 100%;
                height: 39px;
                padding: 0 10px;

                &.invalid {
                    border-color: red;
                }
            }
        }

        .register-email-btn {
            height: 43px;
            line-height: 45px;
        }

        //Dropdowns
        .Dropdown-root {
            width: 17rem;
            margin: 0.5rem 0 2rem 0;
        }

        .Dropdown-control {
            padding: 1rem;
            background: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            opacity: 1;
            border: none;
            padding-left: 22px;
        }

        .Dropdown-arrow {
            top: 22px;
        }

        .Dropdown-menu {
            box-shadow: 0px 3px 6px #00000029;
            border: none;
        }

        .Dropdown-option.is-selected {
            background-color: $evoy-yellow;
            color: white;
        }

        .Dropdown-option {
            padding-left: 22px;
            color: $evoy-black;
        }

        .hulls {
            button {
                box-sizing: border-box;
                width: 269px;
                height: 166px;
                background: #ffffff;
                border: 1px solid #66666658;
                border-radius: 10px;
                margin-right: 20px;
                margin-bottom: 20px;

                &.active {
                    border-color: $evoy-yellow;
                }

                &:focus {
                    outline: 0;
                }
            }
        }

        .box {
            display: none; //DISPLAY WHEN SAVINGS IS ADDED TO STATE
            max-width: 470px;
            height: 71px;
            background: rgba(198, 198, 198, 0.34);
            border-radius: 6px;
            font-size: 18px;
            padding: 0 1rem;
            display: flex;
            display: none; //DISPLAY WHEN SAVINGS IS ADDED TO STATE
            align-items: center;
            margin-top: 2rem;

            &--text {
                opacity: 1;
                @include x-medium-text;
                font-weight: bold;
                word-break: break-word;
            }
        }

        .box--text {
            opacity: 1 !important;
        }

        .center-align {
            display: flex;
            align-items: center;
        }

        .MuiInput-underline:before {
            border-bottom: 0;
        }

        .MuiInputBase-root {
            input {
                font-size: 20px;
                text-align: right;
                width: 79px;
                border: 2px solid #f2f2f2;
                height: 35px;
                padding-right: 15px;
            }
        }

        .input-suffix {
            padding-left: 10px;
        }
    }
}

@media screen and (min-width: $l-desktop) {
    .ReactModal__Content.modal-settings {
        position: absolute;
        margin: 0 auto !important;
        left: 5rem !important;
        top: 5rem !important;
        bottom: 5rem !important;
        right: 5rem !important;
        max-width: 1400px;
        max-height: 800px;
        outline: none;
    }
}

@media screen and (max-width: $l-desktop) {
    .ReactModal__Content.modal-settings {
        left: 16px !important;
        top: 16px !important;
        bottom: 58px !important;
        right: 16px !important;
    }
}

@media screen and (min-width: $desktop) {
    .modal-container {
        background: transparent;
        padding: 0;

        .menu {
            display: initial;
            width: 40%;
            padding-top: 60px;

            p {
                @include x-medium-text;
                padding-right: 1rem;
                padding-bottom: 1rem;
            }

            ol {
                width: 100%;
                display: table;
                margin: 0;
                padding: 0;

                li {
                    @include x-large-text;
                    color: #000000;
                    height: 60px;
                    padding-left: 28px;
                    line-height: 60px;
                    list-style-position: inside;
                    margin-bottom: 40px;
                    cursor: pointer;

                    &.active {
                        background-color: $evoy-yellow;
                        color: $evoy-black;
                    }
                }
            }
        }

        .content {
            padding: 60px 7rem 0 2rem;
            width: 60%;

            h1 {
                font-size: 32px;
                margin: 0;
            }

            p {
                font-size: 18px;
            }

            .Dropdown-root {
                width: 20.9rem;
            }

            .info {
                margin-bottom: 60px;

                h1 {
                    font-size: 40px;
                    margin: 0px;
                }

                p {
                    font-size: 18px;
                    margin: 0;
                    margin-top: 10px;
                }
            }

            .btn-back {
                display: none;
            }

            .savings-block {
                margin-top: 93px;
            }
        }

        .navigation {
            position: absolute;
            bottom: 3rem;
            right: 3rem;
        }

        .hulls {
            margin-right: -9rem;
        }
    }
}
@media screen and (max-width: $m-desktop) and (max-height: $desktop) {
    .modal-settings .modal-container .navigation.self-align {
        position: inherit;
    }
}

@media screen and (max-width: $desktop) {
    .ReactModal__Content {
        position: absolute;
        left: 16px !important;
        top: 16px !important;
        bottom: 50px !important;
        right: 16px !important;
    }

    .modal-settings {
        .modal-container {
            h1 {
                font-size: 22px;
            }

            p {
                font-size: 17px;
            }

            .hulls button {
                margin-bottom: 16px;
            }

            .back-icon {
                transform: rotate(90deg);
                align-self: center;
                margin-right: 6px;
            }

            .btn-back {
                background: none;
                color: black;
                min-width: auto;
                margin: 0;
                padding: 0;
                font-size: 17px;
                text-transform: capitalize;
                font-weight: 400;
                height: auto;
                line-height: 31px;
                margin-top: 10px;
                margin-bottom: -8px;
                outline: none;
                display: flex;

                &:focus {
                    outline: none;
                }
            }
        }

        .info {
            margin-bottom: 26px;
            margin-top: 36px;

            h1 {
                font-size: 28px;
            }
        }

        .content {
            .Dropdown-root {
                margin-top: 23px;
                width: 18rem;
            }

            .slider-space {
                margin-bottom: 20px;
            }

            .MuiInputBase-root {
                margin-left: 0;

                input {
                    font-size: 16px;
                    text-align: center;
                    width: 85px;
                    height: 45px;
                    padding: 0;
                }
            }

            .cost-space {
                margin-top: 13px;
            }

            h3 {
                font-size: 17px;
            }

            .input-container {
                width: 100%;
                margin-bottom: 40px;
                height: 56px;

                input.email {
                    height: 54px;
                    padding: 0 10px;
                    border: 1px solid #c4c2c2;
                    margin-bottom: 4px;
                }
            }

            .register-email-btn {
                margin-left: auto;
                margin-bottom: 15px;
                height: 40px;
                line-height: 42px;
            }
        }

        .Dropdown-control {
            border: 1px solid #c9c9c9;
        }

        .boat-category {
            margin-top: 24px;

            .Dropdown-root {
                margin-bottom: 28px;
            }
        }

        .type-boat {
            p {
                margin: 0;
                font-size: 17px;
                margin-bottom: 24px;
            }
        }

        .hide-for-mobile {
            display: none;
        }

        .slider-space + .center-align {
            display: block;
        }

        .MuiSlider-root {
            width: 100%;

            &::before,
            &::after {
                display: none !important;
            }
        }

        .slider-block {
            margin-bottom: 57px;
        }
    }
}
