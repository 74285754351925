@import "./vars";

@mixin x-small-text {
    font-size: 11px;
}

@mixin small-text {
    font-size: 14px;
}

@mixin medium-text {
    font-size: 16px;
}

@mixin x-medium-text {
    font-size: 18px;
}

@mixin large-text {
    font-size: 20px;
}

@mixin x-large-text {
    font-size: 22px;
}

@mixin xx-large-text {
    font-size: 32px;
}

@mixin yellow-link-button {
    color: $evoy-yellow;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 1rem;
    border: 0;
    background-color: #fff;
    @include x-medium-text;
    font-weight: bold;

    &:focus {
        border: 0;
        box-shadow: none;
    }
}

@mixin yellow-button {
    width: 189px;
    height: 46px;
    background: #fad815 0% 0% no-repeat padding-box;
    text-align: center;
    @include small-text;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
}

@mixin grey-link-button {
    text-decoration: underline;
    @include x-medium-text;
    letter-spacing: 0px;
    color: $evoy-dark-grey-color;
    display: block;
}

@mixin grey-separator-line {
    content: "";
    height: 100%;
    width: 2px;
    position: absolute;
    right: 0;
    background-color: $evoy-dark-grey-color;
    opacity: 0.15;
}
