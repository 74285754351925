@import "../../mixins";
@import "../../vars";

.checkbox-button {
    box-sizing: border-box;
    opacity: 0.9;
    width: 50%;
    margin-bottom: 8px;
    margin-top: 0.5rem;
    background: white;
    border: 1px solid $evoy-grey;
    height: 65px;
    border-radius: 8px;
    padding: 1px;

    @media only screen and (max-width: $desktop) {
        height: 50px;
    }
    @media only screen and (max-width: $m-desktop) {
        width: 100% !important;
    }

    &.active {
        border: 2px solid $evoy-yellow;
        outline: none;
        padding: 0;

        .checkmark-wrapper {
            .checkmark-square {
                border-color: transparent;
            }
        }
    }
    &:focus {
        border: 2px solid $evoy-yellow;
        outline: none;
    }

    .checkmark-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem;

        @media only screen and (max-width: $desktop) {
            margin: 0.5rem;
        }

        .checkmark-square {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 1.5rem;
            width: 1.5rem;
            border: 1px solid $evoy-grey;
            border-radius: 2px;
            margin-right: 1rem;

            .checkmark-image-small {
                height: 1.7rem;
                width: 1.7rem;
            }
        }
    }

    .checkbox-button-label {
        font-size: 18px;
    }
}
