@import "../../vars";
@import "../../mixins";

.price-big-text {
    font-weight: 800;
    font-size: 22px;
}

button.choice-button {
    box-sizing: border-box;
    margin-bottom: 8px;
    font-size: 15px;
    font-family: "HCo Gotham", Sans-serif;

    @media only screen and (min-width: $desktop) {
        padding: 16px 0;
        min-height: 85px;
    }

    @media only screen and (min-width: $desktop) {
        font-size: 18px;
    }

    @media only screen and (min-width: $l-desktop) {
        font-size: 21px;
    }

    .rows-container {
        width: 100%;
    }

    .top-row {
        display: flex;
        width: 100%;

        .left {
            padding-left: 0;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            text-align: left;
            flex: 1;

            @media only screen and (min-width: $desktop) {
                padding-left: 16px;
            }

            span {
                line-height: 1em;
            }

            &.centered {
                padding: 0;
                align-items: center;
            }
        }

        .right {
            display: flex;
            font-weight: bold;
            padding-right: 8px;
            flex-direction: column;
            justify-content: flex-end;
            text-align: right;

            span {
                line-height: 1em;
            }

            .price-from-text {
                font-weight: normal;

                @media only screen and (min-width: $desktop) {
                    font-size: 12px;
                }

                @media only screen and (min-width: $l-desktop) {
                    font-size: 14px;
                }
            }
        }
    }

    .bottom-row {
        display: flex;
        width: 100%;
        font-size: 0.9em;
        align-items: flex-end;

        .left {
            padding-left: 0;
            padding-right: 16px;
            padding-top: 4px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            text-align: left;
            flex: 1;

            @media only screen and (min-width: $desktop) {
                padding-left: 16px;
            }

            &.centered {
                padding: 0;
                align-items: center;
                padding-top: 0.5em;
            }
        }

        .right {
            display: flex;
            padding-right: 8px;
            padding-top: 4px;
            flex-direction: column;
            text-align: right;
        }
    }

    &.ribbon {
        .top-row {
            opacity: 0.7;
            .left {
                padding-left: 20px;

                @media only screen and (min-width: $desktop) {
                    padding-left: 48px;
                }
            }
        }

        .bottom-row {
            opacity: 0.7;
            .left {
                padding-left: 20px;

                @media only screen and (min-width: $desktop) {
                    padding-left: 48px;
                }
            }
        }
    }
}

.ribbon {
    position: relative;
    display: inline-block;
    margin-bottom: 8px;
    background: $evoy-yellow;
}

//"Inspired" by https://stackoverflow.com/questions/63128872/how-can-i-make-a-45-degree-responsive-ribbon-with-folded-corner
.ribbon-shape {
    font-size: 10px;
    font-weight: bold;
    --d: 0.5rem;
    transform: translate(0%, 110%) rotate(-45deg);
    color: $evoy-black;
    text-align: center;
    width: 5.5rem;
    transform-origin: bottom left;
    padding: 0.3rem 0.3rem 1rem;
    clip-path: polygon(
        0 100%,
        0 calc(100% - var(--d)),
        50% calc(100% - var(--d) - 5.5rem / 2),
        100% calc(100% - var(--d)),
        100% 100%,
        calc(100% - var(--d)) calc(100% - var(--d)),
        var(--d) calc(100% - var(--d))
    );
    background: linear-gradient(rgba($color: $evoy-dark-grey-color, $alpha: 0.6) 0 0) bottom/100% var(--d) no-repeat
        $evoy-yellow;
}
.ribbon-shape-wrapper {
    box-sizing: content-box;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    filter: drop-shadow(0px 3px 3px rgba(50, 50, 0, 0.5));
}

@media only screen and (max-width: $desktop) {
    .price-big-text {
        font-size: 13pt;
    }
    .ribbon-shape {
        font-size: 8px;
        --d: 0.3rem;
        width: 3.7rem;
        padding: 0.3rem 0.3rem 0.5rem;
    }
}

.boxtext-right-blue {
    color: $evoy-blue !important;
    font-weight: bold;
}
