@import "../../vars";

.motor-placement-selector {
    display: flex;
    width: 100%;

    button {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;
        width: 100%;
        border-radius: 5px;
        border: 2px solid #e0e0e0;
        margin: 0 4px;
        background-color: #fff;
        outline: none;
        background-repeat: no-repeat;
        background-origin: padding-box;
        transition: background-size 0.25s ease, background-position 0.25s ease;

        @media only screen and (min-width: $m-desktop) {
            height: 127px;
        }

        @media only screen and (min-width: $desktop) and (max-width: $m-desktop) {
            height: 80px;
        }

        div {
            margin-right: 0;
            font-size: 13px;

            @media only screen and (min-width: $m-mobile) {
                font-size: 18px;
            }

            @media only screen and (min-width: $l-mobile) {
                font-size: 21px;
                margin-right: 9%;
            }

            @media only screen and (min-width: $desktop) {
                font-size: 19px;
                margin-right: 0;
            }

            @media only screen and (min-width: $m-desktop) {
                font-size: 19px;
            }

            @media only screen and (min-width: $l-desktop) {
                font-size: 26px;
                margin-right: 9%;
            }

            @media only screen and (min-width: $xl-desktop) {
                font-size: 28px;
            }
        }

        &:first-child {
            margin-left: 0;

            @media only screen and (max-width: $desktop) {
                margin-right: 0px;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &.active {
                    border-right: 3px solid #fad815;
                }
            }
        }

        &:last-child {
            margin-right: 0;

            @media only screen and (max-width: $desktop) {
                margin-left: 0px;
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &.active {
                    border-left: 3px solid #fad815;
                }
            }
        }

        &.active {
            border-color: #fad815;
            border-width: 3px;

            div {
                font-weight: bold;
            }
        }

        &.inboard {
            background-size: auto 100%;
        }

        &.outboard {
            background-size: auto 100%;
        }

        &.inboard:hover,
        &.inboard:focus {
            background-size: auto 110%;
            border-color: #fad815;
            border-width: 3px;
        }

        &.outboard:hover,
        &.outboard:focus {
            background-size: auto 110%;
            border-color: #fad815;
            border-width: 3px;
        }
    }
}
