@import "../mixins";

/********************************
* Common layout for button list
*********************************/
.buttonlist {
    width: 100%;

    h1,
    button {
        width: 100%;
    }

    button {
        padding: 11px;
        display: flex;
        flex-direction: row;
        height: auto;
        background: $box-background;
        opacity: 1;
        border-radius: 5px;
        border: 2px solid #e0e0e0;

        &:disabled {
            pointer-events: none;
        }
        &.not-selected-faded {
            opacity: 0.7;
        }

        &.active,
        &:hover,
        &:focus {
            border: 2px solid $evoy-yellow;
            outline: none;
        }
    }

    button .boxtext:first-child {
        text-align: left;
    }

    button .boxtext:nth-child(2) {
        text-align: right;
    }

    .boxtext {
        height: auto;
        width: 100%;

        p {
            letter-spacing: 0px;
            opacity: 1;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-right: 0;
            margin-left: 0;
        }

        p:first-child {
            font-size: 23px;
            font-weight: bold;
            color: $evoy-black;
        }

        p:nth-child(2) {
            @include large-text;
            color: $evoy-black;
            padding-top: 10px;
            font-weight: 400;
        }
    }
}

.vertical-buttonlist {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: $xl-desktop) {
    .buttonlist .boxtext p:first-child {
        font-size: 21px;
    }
}

@media only screen and (max-width: $l-desktop) {
    .buttonlist .boxtext {
        p:first-child {
            font-size: 21px;
        }

        p:nth-child(2) {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: $m-desktop) {
    .buttonlist {
        .boxtext {
            p:first-child {
                font-size: 15px;
            }

            p:nth-child(2) {
                font-size: 14px;
            }
        }
    }

    .vertical-buttonlist .boxtext:last-child {
        width: 100%;
    }
}

@media only screen and (max-width: $desktop) {
    .buttonlist .boxtext {
        p:first-child {
            font-size: 15px;
        }

        p:nth-child(2) {
            font-size: 14px;
            padding-top: 0px;
        }
    }
}
