@import "../../vars";
@import "../../mixins";

.center {
    justify-content: center;
    align-items: center;
}

.main-text {
    font-size: medium;
    line-height: 2rem;
    width: 30rem;
}

.checkmark-image {
    width: 400px;
    margin-bottom: 10rem;
}

.thankyou-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    background: $evoy-yellow;
    border-radius: 2px;
    width: 269px;
    height: 46px;
    border: none;
    color: $evoy-black;
    @include small-text;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $evoy-lightning;
        }
    }
}

@media only screen and (max-width: $m-desktop) {
    .main-text {
        width: fit-content;
    }
}

@media only screen and (max-width: $desktop) {
    .checkmark-image {
        width: 80%;
        margin-bottom: 10rem;
    }

    .confirmation-text {
        font-size: 1.5rem;
        flex-direction: column;
    }
}
