@import "../../vars";
@import "../../mixins";

footer {
    background-color: $evoy-black;
    color: white;
    height: 140px;

    .change-input-link {
        text-decoration: none !important;
    }

    section.footer-container {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 8px;
        width: 100%;

        @media only screen and (min-width: $desktop) {
            flex-direction: row;
            align-items: flex-start;
            width: auto;
            height: calc(100% - 32px);
        }

        @media only screen and (min-width: $m-desktop) {
            padding-left: 48px;
            padding-right: 48px;
        }

        @media only screen and (min-width: $xl-desktop) {
            padding-left: 96px;
            padding-right: 96px;
        }

        .footer-top {
            display: flex;
            width: 100%;
            flex: 1;

            @media only screen and (min-width: $desktop) {
                width: auto;
                height: 100%;
            }

            .left-part {
                display: flex;
                justify-content: space-between;
                height: 100%;

                @media only screen and (min-width: $desktop) {
                    align-items: center;
                }

                .button-inputs-settings {
                    margin-top: 16px;
                    margin-left: 4px;
                }
            }

            .right-part {
                display: flex;
                justify-content: flex-end;
                flex: 1;
                height: 100%;

                @media only screen and (min-width: $desktop) {
                    align-items: center;
                }
            }
        }

        .footer-bottom {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;

            .footer-button-container {
                margin: 0;

                .nxt-btn {
                    margin: 0;
                    font-size: 16px;
                }
            }

            @media only screen and (min-width: $desktop) {
                width: auto;
                flex-direction: column;
                height: 100%;

                .footer-button-container {
                    margin-bottom: 0;
                    margin-left: 16px;
                }
            }
        }

        .price-container {
            .vat-text {
                text-align: right;
                margin: 0;
                color: $evoy-white;
            }

            .price-text {
                text-align: right;
                margin: 0;
            }

            @media only screen and (min-width: $desktop) {
                margin-right: 32px;
            }
        }

        .small-msg {
            font-size: 12px;
            @media only screen and (min-width: $desktop) {
                font-size: 12px;
            }
            @media only screen and (min-width: $l-desktop) {
                font-size: 16px;
            }
        }

        .large-msg {
            font-size: 20px;
            @media only screen and (min-width: $desktop) {
                font-size: 22px;
            }
            @media only screen and (min-width: $l-desktop) {
                font-size: 31px;
            }
        }

        .submit-btn {
            background: $evoy-yellow;
            border-radius: 3px;
            height: 40px;
            padding: 0 22px;
            font-size: 16px;
            font-weight: bold;
            color: $evoy-black;
            margin: 0;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $evoy-lightning;
                }
            }
        }
    }

    section {
        margin: 0px auto;
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;

        .previous {
            display: none;
        }

        .price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 3rem;
        }

        .button-inputs-settings {
            flex-grow: 1;
        }

        .flex-rows-break {
            display: none;
        }

        .savings {
            margin-right: 38px;

            div {
                display: flex;
                flex-direction: row;
            }
        }

        .small-msg {
            margin: 0;
            color: $evoy-white;
            font-size: 18px;
            font-weight: 100;
        }
        .small-msg-clear {
            opacity: 1;
            margin: 0;
            font-size: 18px;
        }

        .large-msg {
            font-size: 35px;
            margin: 0;
            color: #fff;
        }

        .font-light {
            font-weight: 100;
        }

        .font-normal {
            font-weight: 400;
        }

        .font-bold {
            font-weight: 700;
        }

        .small-msg.invisible-small {
            font-size: 16px;
        }

        .buyer {
            display: none;
        }

        .btn {
            align-self: center;
            justify-self: center;
            white-space: nowrap;
        }

        .btn {
            padding-top: 0;
            position: relative;
        }

        .price {
            justify-self: flex-end;
        }

        .price .large-msg {
            opacity: 1;
        }

        .nxt-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0px 50px;
            background: $evoy-yellow;
            border-radius: 3px;
            border: none;
            color: $evoy-black;
            @include small-text;
            font-weight: bold;
            text-decoration: none;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $evoy-lightning;
                }
            }
        }

        .no_underline {
            text-decoration: none;
        }

        .invisible-small {
            display: none;
        }

        div.Dropdown-root {
            width: 160px;
        }

        .button-inputs-settings span {
            width: 40px;
            height: 40px;
            font-size: 0;
            border-radius: 50%;
            border: 2px solid;
            position: relative;
            align-self: center;
            display: inline-block;
            cursor: pointer;
        }

        .button-inputs-settings span::after {
            content: "";
            height: 10px;
            position: absolute;
            left: 14px;
            top: 16px;
            width: 10px;
            border: solid #ffffff;
            border-width: 0 2px 2px 0;
            display: inline-block;
            border-radius: 1px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }

        .yellow-text {
            color: $evoy-yellow;
        }
    }
}

@media only screen and (min-width: $desktop) {
    footer {
        padding-bottom: 0;

        section {
            padding-left: 20px;
            padding-right: 20px;

            .invisible-small {
                display: inline-block;
                margin: 1px 0 !important;
                cursor: pointer;

                p {
                    margin: 0.5rem 0;
                }
            }

            .ul {
                text-decoration: underline;
            }

            .small-msg {
                margin: 0.5rem 0;
            }

            .large-msg {
                opacity: 1;
            }

            .nxt-btn {
                margin-top: 0.5rem;
            }

            .price {
                justify-self: flex-start;

                p:first-child {
                    text-align: start;
                }
            }

            .savings div {
                display: flex;
                flex-direction: row;
            }

            .buyer {
                display: inline;
                grid-area: first;
            }
        }
    }
}

@media only screen and (max-width: $xl-desktop) {
    footer {
        section {
            height: 100%;
            padding-left: 20px;
            padding-right: 20px;

            .large-msg {
                font-size: 31px;
            }

            .small-msg {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: $l-desktop) {
    footer {
        section {
            width: 90%;
            margin: 0 auto;

            .large-msg {
                font-size: 21px;
            }

            .small-msg {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: $m-desktop) {
    footer {
        section {
            width: auto;

            .large-msg {
                font-size: 22px;

                &.invisible-small {
                    display: block;
                }
            }

            .small-msg {
                font-size: 12px;
            }

            .nxt-btn {
                height: 40px;
                padding: 0 15px;
                align-items: center;

                p {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: $desktop) {
    footer {
        padding-bottom: 0;
        z-index: 10;

        section {
            width: auto;
            flex-wrap: wrap;
            margin-left: 0px;
            align-items: flex-start;
            padding: 14px 16px 0px;
            box-sizing: border-box;

            .yellow-text-mobile {
                color: $evoy-yellow;
            }

            .savings {
                margin-right: 0;
                div {
                    flex-direction: column;
                }

                .invisible-small {
                    display: none !important;
                }
            }

            .price {
                flex-grow: 1;
                text-align: right;
                padding-right: 0;
            }

            div.button-inputs-settings {
                flex-grow: 0;
                display: flex;
                align-self: flex-start;
            }

            div.button-inputs-settings span {
                width: 25px;
                height: 25px;
                border: 1px solid;
            }

            div.button-inputs-settings span:after {
                height: 7px;
                left: 8px;
                top: 10px;
                width: 7px;
            }

            .large-msg {
                font-size: 20px;
            }

            .small-msg {
                font-size: 12px;
            }

            .small-msg-clear {
                font-size: 12px;
            }

            .flex-rows-break {
                display: block;
                flex-basis: 100%;
                order: 4;
                height: 0;
            }

            .next {
                order: 6;
                align-self: flex-start;
                margin-bottom: 15px;
                flex: 1;
                display: flex;
                justify-content: space-between;
            }

            .btn.previous {
                order: 5;
                align-self: flex-start;
                width: 47px;
                display: block;
                margin-bottom: 30px;

                a {
                    background: none;
                    text-align: left;
                    padding: 0;
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: 400;
                    box-shadow: none;
                    margin: 0;

                    p {
                        margin-left: 20px;
                    }

                    p::before {
                        content: "";
                        width: 9px;
                        height: 9px;
                        border-width: 0 0 2px 2px;
                        border-style: solid;
                        -webkit-transform-origin: 100% 0;
                        transform-origin: 100% 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        position: absolute;
                        bottom: 9px;
                        left: 4px;
                    }
                }
            }

            .nxt-btn {
                height: 40px;
                padding: 0 22px;
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: $m-desktop) {
    footer {
        section {
            height: 100%;

            .btn .invisible {
                display: block;
            }
        }
    }
}
