@import "../../vars";

.motor-image {
    display: flex;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
