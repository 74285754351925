@import "../../vars";

/*************************************
* Buttons that should look like links
**************************************/

.save-design-button {
    box-sizing: border-box;
    height: fit-content;
    margin-bottom: 8px;
    width: fit-content;
    background: $evoy-yellow;
    color: $evoy-black;
    border: none;
    font-size: 18px;
    font-weight: bold;
    :focus {
        outline: 1px solid $evoy-black;
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $evoy-lightning;
        }
    }
}
.save-design-text {
    margin: 0;
}

.save-design-header {
    font-size: 14px;
    color: $evoy-black;
    text-transform: uppercase;
    font-weight: 300;
}

.boxtext-right-blue {
    color: $evoy-blue !important;
    font-weight: bold;
}
