//color
$evoy-yellow: #fad815;
$evoy-lightning: #fff4b6;
$evoy-grey: #6a6d6a;
$evoy-dark-grey-color: #666666;
$evoy-black: #131b1d;
$evoy-pearl: #eef2f1;
$evoy-white: white;
$box-background: #fff;
$evoy-blue: #00b0f3;

//breakpoints
$m-mobile: "375px";
$l-mobile: "425px";
$desktop: "896px";
$header: 85px;

$xl-desktop: "1920px";
$l-desktop: "1280px";
$m-desktop: "1050px";
