@import "../../vars";
@import "../../mixins";

.updated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        .dashed-line {
            flex: 1;
            border-bottom: 2px dashed $evoy-yellow;
            opacity: 0.48;
        }

        .updated-button {
            border: none;
            display: flex;
            justify-content: center;
            background: $evoy-yellow;
            border-radius: 3px;
            width: auto;
            height: 37px;
            border: none;
            color: $evoy-black;
            @include small-text;
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;
            margin-left: 10px;
            margin-right: 10px;
            padding: 0 22px;

            p {
                align-self: center;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $evoy-lightning;
                }
            }
        }
    }
}
