@import "./vars";
@import "./mixins";

html,
body {
    margin: 0;
    width: 100%;
    font-family: "HCo Gotham", Sans-serif !important;
    min-width: 320px;
    font-weight: 400;
    overscroll-behavior-y: none;
}

button {
    color: $evoy-black;
    cursor: pointer;
    font-family: "HCo Gotham", Sans-serif !important;
}
