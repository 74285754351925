@import "../../vars";

.battery-comparison-toggle {
    @media only screen and (max-width: $desktop) {
        display: flex;
        justify-content: center;
        margin: 10px;
    }
    button {
        border: none;
        box-sizing: border-box;
        margin-bottom: 8px;
        background-color: $evoy-yellow;
        padding: 2px;
        color: $evoy-black;
        font-size: 16px;
        font-weight: bold;
        outline: none;

        &:focus {
            text-decoration: underline;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $evoy-lightning;
            }
        }
    }
}

.battery-comparison {
    height: 100%;
    background-color: #121010;
    color: #dfdfdf;
    font-family: "HCo Gotham", Sans-serif;

    .battery-comparison-inner {
        display: flex;
        flex-direction: column;
        height: calc(100% - 60px);
        padding: 30px;
        @media only screen and (max-width: $desktop) {
            padding: 10px;
        }

        h1 {
            text-align: left;
            letter-spacing: 0px;
            color: #dfdfdf;
            font-size: 28px;
            font-weight: normal;
            @media only screen and (max-width: $desktop) {
                font-size: 20px;
            }

            span {
                color: $evoy-yellow;
                font-weight: bold;
            }
        }

        .table-container {
            flex: 1;

            table {
                width: 100%;
                height: 100%;
                border-collapse: collapse;

                thead {
                    th {
                        font-size: 18px;
                        color: $evoy-yellow;
                        padding: 10px 0;
                        width: 20%;
                        text-transform: uppercase;
                        @media only screen and (max-width: $desktop) {
                            font-size: 14px;
                        }
                    }

                    th:first-child {
                        width: auto;
                    }
                }

                tbody {
                    td {
                        text-align: center;
                        font-size: 20px;
                        @media only screen and (max-width: $desktop) {
                            font-size: 14px;
                        }

                        span.unit {
                            margin-left: 5px;
                        }
                    }

                    td:first-child {
                        text-align: left;
                    }

                    td.even {
                        background-color: #262424;
                    }
                }
            }
        }
    }

    #close-button {
        box-sizing: border-box;
        cursor: pointer;
        line-height: 1em;
        font-size: 28px;
        position: absolute;
        top: 10px;
        right: 20px;
        border: none;
        background-color: transparent;
        color: #dfdfdf;
        padding: 0;
        margin: 0;
        width: 1em;

        @media only screen and (max-width: $desktop) {
            top: 5px;
            right: 5px;
        }
    }
}

.modal-battery-comparison {
    top: 2% !important;
    bottom: 2% !important;
    margin-left: 2%;
    margin-right: 2%;
    overflow: hidden !important;

    border-radius: 10px;

    &:focus {
        outline: 0;
    }

    @media only screen and (min-width: $desktop) {
        top: 15% !important;
        bottom: 20% !important;
        margin-left: 15%;
        margin-right: 15%;
    }
}
